<mat-sidenav-container class="sidenav-container overflow-hidden">
  <mat-sidenav id="leftSideNav" #drawer class="sidenav" fixedInViewport *ngIf="leftSideNavVisible"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false">
    <app-left-drawer></app-left-drawer>
  </mat-sidenav>
  <mat-sidenav id="rightSideNav" #drawer2 class="sidenav" position="end" *ngIf="rightSideNavVisible"
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false">
    <app-right-drawer></app-right-drawer>
  </mat-sidenav>
  <app-right-drawer></app-right-drawer>
  <mat-sidenav-content fxLayout="column" fxFlex="1 1" class="sidenav-content mat-app-background-1">
    <span fxFlex="44px" fxFlex.xs="40px" fxFlex.sm="40px"></span>
    <app-header fxFlex="65px" fxFlex.sm="65px" fxFlex.xs="65px" class="sticky-header" [isHandset]="isHandset$ | async"></app-header>
    
      <span fxFlex="33px" fxFlex.sm="20px" fxFlex.xs="20px"></span>
      <main>
        <span fxLayout="column" fxFlex="1 1" class="overflow-hidden">
          
          <ng-content></ng-content>
        
      </span>
    </main>
      <app-footer fxFlex="0 0 242px" *ngIf="showFooter" [isHandset]="isHandset$ | async"></app-footer>
    
    
    
  </mat-sidenav-content>
</mat-sidenav-container>
